import styled from "styled-components";
import { Layout } from "antd";
const { Header, Content, Footer } = Layout;

const infoBannerHeight = 57;
const infoBannerHeightMobile = 60;
const infoBannerHeightMobileSmall = 82;

// export const LayoutContainer = styled(Layout)``;
export const LayoutContainer = styled.div``;

export const HeaderContainer = styled(Header)`
  display: block;
  height: ${({ theme }) => theme.layout.header.height}px;
  line-height: unset;
  background: ${({ $isScrolled }) => $isScrolled ? 'white' : 'transparent'} !important;
  padding: 0;
  z-index: 99;
  box-shadow: ${({ $isScrolled, theme }) => 
    $isScrolled ? `0px 3px 6px ${theme.colors.shadow1}` : 'none'};
  position: ${({ $useSimplifiedHeader }) => $useSimplifiedHeader ? 'relative' : 'fixed'};
  width: 100%;
  top: ${({ $useSimplifiedHeader }) => $useSimplifiedHeader ? '0' : `${infoBannerHeight}px`};
  left: 0;
  transition: transform 0.2s ease-out, background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform: translateY(${({ $hideHeader, $useSimplifiedHeader }) => 
    ($hideHeader && !$useSimplifiedHeader) ? '-100%' : '0'});
  will-change: transform;
  margin-top: ${({ $useSimplifiedHeader }) => $useSimplifiedHeader ? `${infoBannerHeight}px` : '0'};

  &.ant-layout-header {
    background: ${({ $isScrolled }) => $isScrolled ? 'white' : 'transparent'} !important;
  }

  /* iPad and smaller devices */
  @media only screen and (max-width: 768px) {
    height: ${({ theme, $useSimplifiedHeader }) => $useSimplifiedHeader ? '70px' : `${theme.layout.header.heightiPad + 0}px`};
    position: ${({ $useSimplifiedHeader }) => $useSimplifiedHeader ? 'relative' : 'fixed'};
    top: ${({ $useSimplifiedHeader }) => $useSimplifiedHeader ? '0' : `${infoBannerHeight}px`};
    transform: translateY(${({ $hideHeader, $useSimplifiedHeader }) => 
      ($hideHeader && !$useSimplifiedHeader) ? '-100%' : '0'});
    margin-top: ${({ $useSimplifiedHeader }) => $useSimplifiedHeader ? `${infoBannerHeightMobile}px` : '0'};
  }
  
  /* Small mobile devices */
  @media only screen and (max-width: 375px) {
    margin-top: ${({ $useSimplifiedHeader }) => $useSimplifiedHeader ? `${infoBannerHeightMobileSmall}px` : '0'};
  }
  
  /* iPad Pro specific */
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    display: ${({ $useSimplifiedHeader }) => $useSimplifiedHeader ? 'block' : 'none'};
  }
  
  /* Mobile phones */
  @media only screen and (max-width: 560px) {
    display: ${({ $useSimplifiedHeader }) => $useSimplifiedHeader ? 'block' : 'none'};
  }
  
  /* Fix for iOS devices */
  @supports (-webkit-touch-callout: none) {
    height: 59px; /* Slightly taller to prevent gap on iOS */
  }
`;

export const HeaderContainerMobile = styled(Header)`
  height: ${({ theme }) => theme.layout.header.heightMobile + 0}px;
  line-height: unset;
  background: ${({ $isScrolled }) => $isScrolled ? 'white' : 'transparent'} !important;
  padding: 0;
  z-index: 99;
  box-shadow: ${({ $isScrolled, theme }) => 
    $isScrolled ? `0px 3px 6px ${theme.colors.shadow1}` : 'none'};
  position: fixed;
  width: 100%;
  top: ${infoBannerHeightMobile}px;
  left: 0;
  transition: transform 0.2s ease-out, background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform: translateY(${({ $hideHeader }) => $hideHeader ? '-100%' : '0'});
  will-change: transform;
  margin-top: 0;

  &.ant-layout-header {
    background: ${({ $isScrolled }) => $isScrolled ? 'white' : 'transparent'} !important;
  }

  /* iPad and smaller devices */
  @media only screen and (max-width: 768px) {
    position: fixed;
    top: ${infoBannerHeightMobile}px;
    box-shadow: ${({ $isScrolled, theme }) => 
      $isScrolled ? `0px 3px 6px ${theme.colors.shadow1}` : 'none'};
    transform: translateY(${({ $hideHeader }) => $hideHeader ? '-100%' : '0'});
  }
  
  /* iPad Pro specific - ensure it's visible */
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    display: block;
    position: fixed;
    top: ${infoBannerHeightMobile}px;
  }

  /* Small mobile devices */
  @media only screen and (max-width: 375px) {
    top: ${infoBannerHeightMobileSmall}px;
  }

  /* Fix for iOS devices */
  @supports (-webkit-touch-callout: none) {
    margin-top: -20px; /* Larger negative margin to fix gap on iOS */
    top: calc(${infoBannerHeightMobile}px - 1px); /* Adjust top position slightly */
    
    @media only screen and (max-width: 375px) {
      top: calc(${infoBannerHeightMobileSmall}px - 1px);
    }
  }
`;

export const MainContainer = styled(Content).withConfig({
  shouldComponentUpdate: true,
  componentId: 'MainContainer',
  shouldComponentUpdateForProp: prop => prop !== 'isHomePage'
})`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  padding-top: ${({ theme, $isHomePage, $useSimplifiedHeader }) => {
    if ($isHomePage) return '0';
    if ($useSimplifiedHeader) return '0';
    return `${theme.layout.header.height + 57}px`;
  }};

  /* iPad and smaller tablets */
  @media only screen and (max-width: 768px) {
    padding-top: ${({ theme, $isHomePage, $useSimplifiedHeader }) => {
      if ($isHomePage) return '0';
      if ($useSimplifiedHeader) return '0';
      return `${theme.layout.header.heightiPad + 76}px`;
    }};
  }
  
  /* iPad Pro specific */
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    padding-top: ${({ theme, $isHomePage, $useSimplifiedHeader }) => {
      if ($isHomePage) return '0';
      if ($useSimplifiedHeader) return '0';
      return `${theme.layout.header.heightMobile + 78}px`;
    }};
  }

  /* Mobile phones */
  @media only screen and (max-width: 560px) {
    padding-top: ${({ theme, $isHomePage, $useSimplifiedHeader }) => {
      if ($isHomePage) return '0';
      if ($useSimplifiedHeader) return '0';
      return `${theme.layout.header.heightMobile + 78}px`;
    }};
  }
`;

export const ContainerWithMax = styled.div`
  width: 95%;
  max-width: ${({ theme }) => theme.layout.content.maxWidth}px;
  margin: 0 auto;
  background-color: #ffffff;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FooterContainer = styled(Footer)`
  line-height: unset;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0;
  width: 100%;
`;

