import React from "react";
import Link from "next/link";
import { Container, Wrapper, ContentWrap, LogoSection } from "./styled";
import LogoDefault from "../Logos/LogoDefault";

function SimplifiedHeader({ $isScrolled }) {
  return (
    <Container $isScrolled={$isScrolled}>
      <Wrapper>
        <ContentWrap>
          <LogoSection>
            <Link href="/">
              <a role="button" aria-label="Classy Closets logo, life organized, home page">
                <LogoDefault $isScrolled={$isScrolled} />
              </a>
            </Link>
          </LogoSection>
        </ContentWrap>
      </Wrapper>
    </Container>
  );
}

export default SimplifiedHeader; 