import React, { useContext, useCallback, useState, useEffect } from "react";
import SubMenuItem from "./SubMenuItem";
import {
  Container,
  ShadowDiv,
  ContentWrapper,
  SubMenuSection,
  MenuGroupWrap,
} from "./styled";
import MainContainerContext from "../../../context/MainContainer";

function SubMenu({ childItems, handleHover, hoverIndex, $isScrolled, position, isProductsMenu, style }) {
  const { getContainer } = useContext(MainContainerContext);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  
  // Mark component as mounted
  useEffect(() => {
    setIsMounted(true);
  }, []);
  
  // Safely get container width on client-side only
  useEffect(() => {
    // Only run on client-side
    if (typeof window !== 'undefined') {
      try {
        const container = getContainer();
        if (container && container.offsetWidth) {
          setContainerWidth(container.offsetWidth);
        }
      } catch (error) {
        console.error('Error getting container width:', error);
      }
    }
  }, [getContainer]);

  const onMouseEnter = useCallback(() => {
    if (handleHover) {
      handleHover(true, hoverIndex);
    }
  }, [handleHover, hoverIndex]);

  const onMouseLeave = useCallback(() => {
    if (handleHover) {
      handleHover(false, hoverIndex);
    }
  }, [handleHover, hoverIndex]);

  // Combine passed style with our own visibility control
  const combinedStyle = {
    ...style,
    visibility: isMounted ? 'visible' : 'hidden'
  };

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      containerWidth={containerWidth}
      $isScrolled={$isScrolled}
      position={position}
      isProductsMenu={isProductsMenu}
      style={combinedStyle}
    >
      <ContentWrapper>
        <SubMenuSection $isScrolled={$isScrolled}>
          <MenuGroupWrap isProductsMenu={isProductsMenu}>
            {childItems &&
              childItems.map((item, key) => (
                <SubMenuItem key={key} item={item} $isScrolled={$isScrolled} />
              ))}
          </MenuGroupWrap>
        </SubMenuSection>
      </ContentWrapper>
      <ShadowDiv isProductsMenu={isProductsMenu} />
    </Container>
  );
}

export default SubMenu;
