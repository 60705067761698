import { useEffect, useState } from "react";

export default function useIPad() {
  const [isIPad, setIsIPad] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    
    const checkIPad = () => {
      // Check if device is iPad
      const isIPadDevice = 
        /iPad/.test(navigator.userAgent) || 
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
        // iPad Pro detection
        (/Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1);
      
      // Log for debugging
      console.log('iPad detection:', {
        isIPadDevice,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        maxTouchPoints: navigator.maxTouchPoints,
        timestamp: new Date().toISOString()
      });
      
      setIsIPad(isIPadDevice);
    };
    
    checkIPad();
    
    // Check on resize as well (for orientation changes)
    window.addEventListener('resize', checkIPad);
    return () => window.removeEventListener('resize', checkIPad);
  }, []);

  return isIPad;
} 