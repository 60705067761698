import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 100%;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  line-height: 32px;
  
  ${props => props.isHighlighted && css`
    background-color: ${props => props.theme.colors.classyRed};
    border-radius: 0px;
    margin: 0 5px;
    
    &:hover {
      background-color: ${props => props.theme.colors.classyRed || '#8a1717'};
    }
  `}
`;

export const MenuText = styled.span`
  font-family: "Montserrat Medium";
  font-size: 15px;
  font-weight: 500;
  /* Default color based on scroll state */
  color: ${props => props.$isScrolled ? props.theme.colors.black : 'white'};
  transition: all 0.3s ease;
  
  ${props => props.active && !props.isHighlighted && css`
    color: ${props => props.theme.colors.primary};
  `}
  
  /* Highlighted item always has white text regardless of scroll state */
  ${props => props.isHighlighted && css`
    color: white !important;
  `}
`;

export const MenuIconWrap = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
  
  .chevron-down {
    transition: transform 0.3s ease;
    
    path {
      /* Default stroke color based on scroll state */
      stroke: ${props => props.$isScrolled ? props.theme.colors.black : 'white'};
      transition: stroke 0.3s ease;
    }
  }
  
  .chevron-down.open {
    transform: rotate(180deg);
  }
  
  ${props => props.active && !props.isHighlighted && css`
    .chevron-down path {
      stroke: ${props => props.theme.colors.primary};
    }
  `}
  
  /* Highlighted item always has white chevron regardless of scroll state */
  ${props => props.isHighlighted && css`
    .chevron-down path {
      stroke: white !important;
    }
  `}
`;
