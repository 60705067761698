import React, { useState, useEffect } from "react";
import { withTheme } from "styled-components";
import Link from "next/link";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import Menu from "../Menu";
import {
  Container,
  LogoSection,
  ContentWrap,
  CallUsBtnWrap,
  Wrapper,
  CustomPButton,
} from "./styled";
import { eventClickPhoneNumber } from "../../services/analytics/ga4CustomEvents";
import LogoDefault from "../Logos/LogoDefault";

function Header({ theme, $isScrolled }) {
  const { isDesktop, isIPad } = useWindowDimensions();
  const renderCountRef = React.useRef(0);
  const [isMounted, setIsMounted] = useState(false);
  
  // Determine if we should show the desktop menu
  // iPads should use mobile layout
  const showDesktopMenu = isDesktop && !isIPad;

  // Set isMounted to true after component mounts
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Debug render
  console.info('🎯 Header Component:', {
    event: 'rendering',
    isScrolled: $isScrolled,
    isDesktop,
    isIPad,
    showDesktopMenu,
    renderCount: ++renderCountRef.current,
    timestamp: new Date().toISOString()
  });

  // Debug scroll state changes
  React.useEffect(() => {
    console.info('🎯 Header Component:', {
      event: 'scroll state changed',
      isScrolled: $isScrolled,
      isDesktop,
      isIPad,
      showDesktopMenu,
      timestamp: new Date().toISOString()
    });
  }, [$isScrolled]);

  const handleClickCallUs = () => {
    const phoneNumber = "18666427703";
    window.open(`tel:${phoneNumber}`);
  };

  return (
    <Container $isScrolled={$isScrolled}>
      <Wrapper>
        <ContentWrap>
          <LogoSection>
            <Link href="/">
              <a role="button" aria-label="Classy Closets logo, life organized, home page">
                <LogoDefault $isScrolled={$isScrolled} />
              </a>
            </Link>
            {/* Always render Menu but control visibility with CSS to avoid hydration errors */}
            <div style={{ display: isMounted && !showDesktopMenu ? 'none' : 'block' }}>
              <Menu 
                $isScrolled={$isScrolled} 
                key={`menu-${$isScrolled}`} // Force re-render when scroll state changes
              />
            </div>
          </LogoSection>
        </ContentWrap>
      </Wrapper>
    </Container>
  );
}

export default withTheme(Header);
