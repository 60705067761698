import styled, { css } from "styled-components";

const expandedStyle = css`
  overflow-y: scroll;
  min-height: 100vh;
  max-height: 100vh;
  visibility: visible;
  /* -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in; */
`;

const collapsedStyle = css`
  overflow: hidden;
  max-height: 0;
  visibility: hidden;
  /* -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1); */
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 90;
  padding-left: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.mobile}px;
  padding-right: ${({ theme }) =>
    theme.layout.content.paddingHorizontal.mobile}px;
  ${collapsedStyle};
  ${({ expanded }) => expanded === "yes" && expandedStyle};
`;

export const MenuWrap = styled.div`
  display: flex;
  padding-top: 7px;
  flex-direction: column;
`;

export const NavFooter = styled.div`
  padding-top: 24px;
`;

export const TextElm = styled.div`
  font-family: "Open Sans";
  font-size: 12px;
  color: ${({ theme }) => theme.colors.borderColor1};
  padding-bottom: 8px;
  color: black;
  .link{
    color: black;
  &:hover {
    color: black;
  }
}
  
`;
