import React, { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { LogoMobile } from "../Logos";
import NavMenu from "./NavMenu";
import {
  Container,
  Header,
  HamburgerBtn,
  PhoneIcon,
  NavContainer,
} from "./styled";
import { desktopMenus } from "../Menu/_nav";
import { eventClickPhoneNumber } from "../../services/analytics/ga4CustomEvents";
import { useRouter } from "next/router";

const HeaderMobile = ({ $isScrolled }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { query, isReady } = useRouter();

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isReady) {
      setIsExpanded(false);
    }
  }, [query]);

  const handleClickCallUs = () => {
    const phoneNumber = "18665802280";
    window.open(`tel:${phoneNumber}`);

    eventClickPhoneNumber(
      "18666427703",
      "Clicks on phone numbers in the mobile header",
    );
  };

  return (
    <Container $isScrolled={$isScrolled}>
      <Header $isScrolled={$isScrolled}>
        <HamburgerBtn
          tabIndex="0"
          aria-label="open menu"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleClick();
            }
          }}
          onClick={handleClick}
          $isScrolled={$isScrolled}
        >
          {isExpanded ? (
            <Image
              src="/icons/icon_times-close.svg"
              width={24}
              height={24}
              alt=""
            />
          ) : (
            <Image
              src="/icons/icon_bar-solid.svg"
              width={24}
              height={24}
              alt=""
            />
          )}
        </HamburgerBtn>
        <Link href="/" passHref>
          <a>
            <LogoMobile $isScrolled={$isScrolled} />
          </a>
        </Link>
        <PhoneIcon onClick={handleClickCallUs} $isScrolled={$isScrolled}>
          <Image
            src="/icons/phone_black.png"
            alt=""
            layout="fill"
            objectFit="contain"
          />
        </PhoneIcon>
      </Header>
      <NavContainer isExpanded={isExpanded}>
        <NavMenu 
          expanded={isExpanded ? "yes" : "no"}
          menuLists={desktopMenus}
          setIsExpanded={setIsExpanded}
        />
      </NavContainer>
    </Container>
  );
};

export default HeaderMobile;
