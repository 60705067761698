import React from "react";
import PropTypes from "prop-types";
import SubMenuItem from "./SubMenuItem";
import { Container } from "./styled";

const SubNavMenu = ({ items, expanded, parentTitle }) => {
  const isProductsMenu = parentTitle === "Products";

  return (
    <Container $expanded={expanded} $isProductsMenu={isProductsMenu}>
      {items.map((item, key) => (
        <SubMenuItem key={key} item={item} />
      ))}
    </Container>
  );
};

SubNavMenu.propTypes = {
  items: PropTypes.array,
  expanded: PropTypes.bool,
  parentTitle: PropTypes.string,
};

export default SubNavMenu;
