import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormConsultation from '../../../containers/Forms/Consultation-Classic';
import { LocationContext } from '../../../context/LocationContext';
import dynamic from 'next/dynamic';
import ModalContactSuccess from '../../../containers/Modals/ModalContactSuccess';
import { createGlobalStyle } from 'styled-components';

// Global style to fix z-index issues with Ant Design Select dropdowns
const GlobalStyle = createGlobalStyle`
  .ant-select-dropdown {
    z-index: 999 !important;
  }
`;

// Use NoSSR to prevent hydration issues
const NoSSRFormConsultation = dynamic(() => Promise.resolve(FormConsultation), {
  ssr: false
});

const StyledMobileStartButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 1rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 99 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  transform: translateY(${props => props.$isScrolled ? '0' : '100%'});
  @media (min-width: 769px) {
    display: none;
  }
`;

const Button = styled.button`
  width: 100% !important;
  padding: 15px !important;
  background-color: #762738 !important;
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
  font-family: "Montserrat Medium" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
  display: block !important;

  &:hover {
    background-color: #8f2f45 !important;
  }

  &:active {
    transform: scale(0.98) !important;
  }
`;

const ModalOverlay = styled.div`
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #762738 !important;
  z-index: 999999 !important;
  display: ${props => props.isOpen ? 'flex' : 'none'} !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 20px !important;
  overflow-y: auto !important;
`;

const CloseButton = styled.button`
  position: absolute !important;
  top: 20px !important;
  right: 20px !important;
  background: none !important;
  border: none !important;
  color: white !important;
  font-size: 24px !important;
  cursor: pointer !important;
  z-index: 1000000 !important;
`;

const ModalContent = styled.div`

  padding: 1.5rem;
  border-radius: 1rem 1rem 0 0;
  z-index: 999 !important;
  max-height: 90vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const FormContainer = styled.div`
  position: relative;
  z-index: 1000 !important;
  width: 100vw;
  padding:2rem;
  max-width: 600px !important;
  margin: 60px auto 20px !important;
  font-family: "Roboto", sans-serif !important;
  
  h3 {
    color: white !important;
    text-align: center !important;
    margin-bottom: 20px !important;
    margin-top: 8px !important;
    font-family: "Roboto", sans-serif !important;
  }

  h2 {
    color: white !important;
    text-align: center !important;
    margin-bottom: 8px !important;
    font-family: "Roboto", sans-serif !important;
  }

  .ant-form-item-label > label,
  .ant-form-item-label > label.ant-form-item-required,
  .ant-form label,
  .ant-form-item label,
  label {
    color: white !important;
    font-size: 10px !important;
    font-family: "Roboto", sans-serif !important;
    line-height: 1.2 !important;
    height: auto !important;
  }

  .ant-form-item-label,
  .ant-form-item {
    font-size: 10px !important;
    line-height: 1.2 !important;
    margin-bottom: 4px !important;
    margin-top: 4px !important;
    font-family: "Roboto", sans-serif !important;
  }

  .ant-form {
    label {
      font-size: 10px !important;
      font-family: "Roboto", sans-serif !important;
    }
    input, textarea, select {
      font-family: "Roboto", sans-serif !important;
    }
  }

  .ant-btn {
    background-color: #762738 !important;
    color: white !important;
    border: 2px solid white !important;
    font-family: "Roboto", sans-serif !important;
    
    span {
      color: white !important;
      font-family: "Roboto", sans-serif !important;
    }
    
    &:hover {
      background-color: #8f2f45 !important;
      border-color: white !important;
      
      span {
        color: white !important;
      }
    }
  }
`;

const SuccessContent = styled.div`
  text-align: center;
  color: white;
  padding: 20px;
  
  h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  p {
    font-size: 16px;
  }
`;

const MobileStartButton = ({ $isScrolled, $hideHeader, onClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const location = useContext(LocationContext);
  const formValuesRef = useRef({});

  // Detect iOS devices
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                         (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      setIsIOS(isIOSDevice);
      console.log("MobileStartButton: iOS detection:", isIOSDevice);
      setIsMounted(true);
    }
  }, []);

  // Update form values whenever location changes
  useEffect(() => {
    if (location?.city && location?.state) {
      const values = {
        showroom: `${location.city}, ${location.state}`,
        tenantId: location.tenantId,
        city: location.userLocation?.city || '',
        state: location.userLocation?.state || ''
      };
      console.log('MobileStartButton: Updated form values ref:', values);
      formValuesRef.current = values;
      setIsFormReady(true);
    } else {
      console.info('MobileStartButton: Location data incomplete:', location);
      setIsFormReady(true); // Still mark as ready so form can be used manually
    }
  }, [location]);

  const handleButtonClick = () => {
    console.log('MobileStartButton: Button clicked, opening modal');
    setIsModalOpen(true);
    setShowSuccessModal(false);
    
    // For iOS devices, we need to ensure the form is ready
    if (isIOS) {
      console.log('MobileStartButton: iOS device detected, ensuring form is ready');
      // Double-check location data
      if (location?.city && location?.state) {
        const values = {
          showroom: `${location.city}, ${location.state}`,
          tenantId: location.tenantId,
          city: location.userLocation?.city || '',
          state: location.userLocation?.state || ''
        };
        console.log('MobileStartButton: iOS - refreshing form values:', values);
        formValuesRef.current = values;
      }
    }
    
    if (onClick) onClick();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSuccess = () => {
    console.log('MobileStartButton: Form submitted successfully');
    // Close the form modal and show the success modal
    setIsModalOpen(false);
    setShowSuccessModal(true);
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };

  // Cleanup effect
  useEffect(() => {
    return () => {
      setIsModalOpen(false);
      setShowSuccessModal(false);
    };
  }, []);

  return (
    <>
      {/* Add global style fix for select dropdowns */}
      {isModalOpen && <GlobalStyle />}
      
      <StyledMobileStartButton 
        $isScrolled={$isScrolled} 
        className="mobile-start-button"
        style={{ opacity: isMounted ? 1 : 0 }}
      >
        <Button onClick={handleButtonClick} type="button">
          Start Your Project
        </Button>
      </StyledMobileStartButton>

      {isMounted && (
        <ModalOverlay isOpen={isModalOpen}>
          <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
          <ModalContent>
            <FormContainer>
              <h2>Start Your Project</h2>
              <h3>Schedule your FREE Consultation</h3>
              <NoSSRFormConsultation 
                formId="mobile-quick-contact"
                variant="small"
                submitButton="Get Started"
                dispatchDestination="contact"
                onFormSuccess={handleFormSuccess}
                initialValues={formValuesRef.current}
                isFormReady={isFormReady}
                isIOS={isIOS}
              />
            </FormContainer>
          </ModalContent>
        </ModalOverlay>
      )}

      <ModalContactSuccess 
        open={showSuccessModal}
        onRequestClose={handleSuccessModalClose}
      />
    </>
  );
};

MobileStartButton.propTypes = {
  $isScrolled: PropTypes.bool,
  $hideHeader: PropTypes.bool,
  onClick: PropTypes.func
};

export default MobileStartButton; 