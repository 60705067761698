import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Container, MenuText, MenuIconWrap } from "./styled";

const ChevronImage = (props) => {
  return (
    <span className={props.className}>
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 1.5L6 6L10.5 1.5"
          stroke="#1F1F1F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

const MenuItem = ({
  menuType,
  active,
  children,
  menuIcon,
  handleClick,
  handleHover,
  index,
  isParentItem,
  activeIndex,
  $isScrolled,
  isHighlighted,
  onPositionUpdate
}) => {
  const menuItemRef = useRef(null);

  const getPosition = useCallback(() => {
    if (menuItemRef.current) {
      const rect = menuItemRef.current.getBoundingClientRect();
      return {
        left: rect.left + (rect.width / 2),
        width: rect.width,
        top: rect.bottom - 48
      };
    }
    return null;
  }, []);

  const onClick = useCallback(() => {
    console.log('MenuItem clicked, index:', index);
    const position = getPosition();
    if (handleClick) {
      handleClick(position);
    }
  }, [handleClick, getPosition, index]);

  const onMouseEnter = useCallback(() => {
    if (handleHover) {
      handleHover(true, index);
    }
    if (onPositionUpdate) {
      const position = getPosition();
      if (position) {
        onPositionUpdate(position);
      }
    }
  }, [handleHover, index, onPositionUpdate, getPosition]);

  const onMouseLeave = useCallback(() => {
    if (handleHover) {
      handleHover(false, index);
    }
  }, [handleHover, index]);

  const onKeyUp = useCallback((e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      const position = getPosition();
      handleClick(position);
    }
  }, [handleClick, getPosition]);

  return (
    <Container
      ref={menuItemRef}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyUp={onKeyUp}
      tabIndex={0}
      active={active}
      $isScrolled={$isScrolled}
      isHighlighted={isHighlighted}
      aria-label={`${children}, dropdown menu, ${active && activeIndex === index ? "open" : "closed"}`}
      className="menu-item"
    >
      {isParentItem ? (
        <>
          <MenuText 
            active={active} 
            $isScrolled={$isScrolled}
            isHighlighted={isHighlighted}
          >
            {children}
          </MenuText>
          <MenuIconWrap 
            active={active} 
            $isScrolled={$isScrolled}
            isHighlighted={isHighlighted}
          >
            <ChevronImage className={`chevron-down ${active && activeIndex === index ? "open" : ""}`} />
          </MenuIconWrap>
        </>
      ) : (
        <MenuText 
          active={active} 
          $isScrolled={$isScrolled}
          isHighlighted={isHighlighted}
        >
          {children}
        </MenuText>
      )}
    </Container>
  );
}

MenuItem.propTypes = {
  menuType: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  menuIcon: PropTypes.bool,
  handleClick: PropTypes.func,
  handleHover: PropTypes.func,
  index: PropTypes.number,
  isParentItem: PropTypes.bool,
  activeIndex: PropTypes.number,
  $isScrolled: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  onPositionUpdate: PropTypes.func
};

export default MenuItem;
