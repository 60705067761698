import React, { useState, useCallback, useEffect, useRef } from "react";
import Link from "next/link";
import MenuItem from "./MenuItem";
import SubMenu from "./SubMenu";
import ContactFormDropdown from "./ContactFormDropdown";
import MobileStartButton from "./MobileStartButton";
import { Container, MenuWrap } from "./styled";
import { desktopMenus } from "./_nav";

const Menu = ({ $isScrolled }) => {
  // Find the index of the contact form menu item
  const contactFormIndex = desktopMenus.findIndex(item => item.type === 'contact_form');
  
  const [activeIndex, setActiveIndex] = useState(null); // Start with form closed
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [submenuPosition, setSubmenuPosition] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const menuRef = useRef(null);
  const hoverTimeoutRef = useRef(null);
  const renderCountRef = useRef(0);

  // Set isMounted to true after component mounts
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Debug mount and initial render
  useEffect(() => {
    console.info('🔍 Menu Component:', {
      event: 'mounted',
      isScrolled: $isScrolled,
      renderCount: ++renderCountRef.current,
      timestamp: new Date().toISOString()
    });
  }, []);

  // Debug prop changes
  useEffect(() => {
    console.info('🔍 Menu Component:', {
      event: 'scroll state changed',
      isScrolled: $isScrolled,
      renderCount: ++renderCountRef.current,
      activeIndex,
      hoveredIndex,
      timestamp: new Date().toISOString()
    });
  }, [$isScrolled]);

  // Debug render
  console.info('🔍 Menu Component:', {
    event: 'rendering',
    isScrolled: $isScrolled,
    renderCount: renderCountRef.current,
    timestamp: new Date().toISOString()
  });

  // Check if we're on mobile
  useEffect(() => {
    if (typeof window === 'undefined') return;
    
    const checkMobile = () => {
      // Check if device is iPad/tablet
      const isIPad = /iPad/.test(navigator.userAgent) || 
                    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      
      // Consider iPads as mobile devices
      const isMobileView = window.innerWidth <= 768 || isIPad;
      
      // Check if device is iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                   (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
                   
      console.log('Window width check:', {
        width: window.innerWidth,
        isMobileView,
        isIOS,
        isIPad,
        timestamp: new Date().toISOString()
      });
      setIsMobile(isMobileView);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  // Add a custom CSS class to the body element to handle desktop menu visibility
  useEffect(() => {
    if (typeof window === 'undefined') return;
    
    if (!isMobile) {
      document.body.classList.add('desktop-menu-always-visible');
    } else {
      document.body.classList.remove('desktop-menu-always-visible');
    }
    
    return () => {
      document.body.classList.remove('desktop-menu-always-visible');
    };
  }, [isMobile]);

  // Set up click outside handler
  useEffect(() => {
    if (typeof window === 'undefined') return;
    
    const handleClickOutside = (event) => {
      // Check if the click is outside the menu and the contact form
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        const contactForm = document.querySelector('.contact-form-dropdown');
        const isClickInsideContactForm = contactForm && contactForm.contains(event.target);
        
        // Only close menus if the click is outside both the menu and the contact form
        if (!isClickInsideContactForm) {
          // Don't close the contact form when clicking outside
          if (activeIndex !== contactFormIndex) {
            console.log('Closing menu due to outside click');
            setActiveIndex(null);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeIndex, contactFormIndex]);

  const handleClick = useCallback((index) => {
    const menuItem = desktopMenus[index];
    if (menuItem.type === 'contact_form') {
      // Toggle the form visibility
      console.log('Toggling contact form visibility, current state:', activeIndex === index ? 'open' : 'closed');
      setActiveIndex(prev => {
        const newValue = prev === index ? null : index;
        console.log('Setting activeIndex to:', newValue);
        return newValue;
      });
    }
  }, [activeIndex]);

  const handleMobileButtonClick = useCallback(() => {
    // Toggle the contact form visibility
    console.log('Mobile button clicked, toggling contact form');
    
    // For mobile, we want to ensure the form is properly initialized
    // So we'll always set it to the contact form index first
    setActiveIndex(contactFormIndex);
    
    // If it's already open, close it after a short delay
    // This ensures the form has time to initialize properly
    if (activeIndex === contactFormIndex) {
      setTimeout(() => {
        setActiveIndex(null);
      }, 50);
    }
  }, [contactFormIndex, activeIndex]);

  const handleHover = useCallback((isHovered, index) => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    if (isHovered) {
      setHoveredIndex(index);
    } else {
      hoverTimeoutRef.current = setTimeout(() => {
        setHoveredIndex(null);
      }, 100);
    }
  }, []);

  const handlePositionUpdate = useCallback((position, index) => {
    const menuItem = desktopMenus[index];
    if (menuItem.type === 'parent') {
      setSubmenuPosition(position);
    }
  }, []);

  // Force reset state when scrolled state changes, but keep contact form open
  useEffect(() => {
    console.log('Scroll state changed, activeIndex:', activeIndex, 'contactFormIndex:', contactFormIndex);
    
    // Don't reset activeIndex on scroll - let the user control it
    setHoveredIndex(null);
    setSubmenuPosition(null);
  }, [$isScrolled]);

  // Log activeIndex changes
  useEffect(() => {
    console.log('activeIndex changed:', activeIndex);
  }, [activeIndex]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  // Update the MenuItem component to properly handle clicks
  const renderMenuItem = (item, index) => {
    // Use the original $isScrolled value for styling
    // This ensures the menu text color is white when not scrolled on homepage
    const effectiveIsScrolled = $isScrolled;
    
    if (item.type === "parent") {
      return (
        <MenuItem
          key={`parent-${index}`}
          index={index}
          active={hoveredIndex === index}
          handleClick={() => handleClick(index)}
          handleHover={handleHover}
          activeIndex={hoveredIndex}
          menuIcon={item.type === "parent"}
          isParentItem={true}
          $isScrolled={effectiveIsScrolled}
          onPositionUpdate={(position) => handlePositionUpdate(position, index)}
        >
          {item.title}
        </MenuItem>
      );
    } else if (item.type === "contact_form") {
      return (
        <MenuItem
          key={`contact-${index}`}
          index={index}
          active={activeIndex === index}
          handleClick={() => handleClick(index)}
          handleHover={handleHover}
          activeIndex={activeIndex}
          menuIcon={true}
          isParentItem={true}
          $isScrolled={effectiveIsScrolled}
          onPositionUpdate={(position) => handlePositionUpdate(position, index)}
          isHighlighted={item.isHighlighted}
        >
          {item.title}
        </MenuItem>
      );
    } else {
      return (
        <Link href={item.href} key={`link-${index}`}>
          <a>
            <MenuItem
              index={index}
              active={hoveredIndex === index}
              handleClick={() => handleClick(index)}
              handleHover={handleHover}
              $isScrolled={effectiveIsScrolled}
            >
              {item.title}
            </MenuItem>
          </a>
        </Link>
      );
    }
  };

  return (
    <>
      <Container 
        ref={menuRef} 
        $isScrolled={$isScrolled}
        className="menu-container"
      >
        <MenuWrap>
          {desktopMenus.map((item, index) => (
            <div key={`${item.title}-${index}`}>
              {renderMenuItem(item, index)}
            </div>
          ))}
        </MenuWrap>

        {/* Always render SubMenu with consistent structure for SSR */}
        <div className="submenu-container" style={{ visibility: isMounted ? 'visible' : 'hidden', display: (isMounted && hoveredIndex !== null && desktopMenus[hoveredIndex]?.type === "parent" && desktopMenus[hoveredIndex].children) ? 'block' : 'none' }}>
          {desktopMenus.map((item, index) => (
            item.type === "parent" && item.children && (
              <SubMenu
                key={`submenu-${index}`}
                childItems={item.children}
                hoverIndex={hoveredIndex}
                handleHover={handleHover}
                $isScrolled={$isScrolled}
                position={submenuPosition}
                isProductsMenu={item.title === "Products"}
                style={{ 
                  display: isMounted && hoveredIndex === index ? 'block' : 'none' 
                }}
              />
            )
          ))}
        </div>

        {/* Always render ContactFormDropdown with consistent structure for SSR */}
        <ContactFormDropdown
          key={`contact-form-${contactFormIndex}`}
          $isScrolled={$isScrolled}
          isOpen={isMounted && activeIndex === contactFormIndex}
          onClose={() => setActiveIndex(null)}
        />
      </Container>
      {isMounted && (
        <MobileStartButton 
          $isScrolled={$isScrolled} 
          onClick={handleMobileButtonClick}
        />
      )}
    </>
  );
};

export default Menu;
