import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LocationIcon from "../LocationIcon";
import Image from "next/image";
{/*import PhoneIcon from "../PhoneIcon"; */ }
import closestShowroom from "../../utils/closestLocation";
import styled from "styled-components";
import { jsonData } from "../LocationsMap/_data";
import Spacer from '../../components/Content/Spacer';

import {
  Container,
  InfoText,
  bannerStyle,
  InfoTextLink,
  Column,
  Row,
  ClearLeft,
  ClearRight,
} from "./styled";
import LoadingSpinner from "../LoadingSpinner";
import Link from "next/link";

function InfoBanner({ type, innerRef }) {
  const [loading, setLoading] = useState(false);
  const [showroom, setShowroom] = useState(null);
  const linkStyle = { color: '#ffffff' };

  const getCorrectLatLonAndStoreInLocalStorage = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLoading(true);
          const { latitude, longitude } = position.coords;
          const closestShowroomToUser = closestShowroom(latitude, longitude, jsonData.features);
          const closestTenant = closestShowroom(latitude, longitude, jsonData.features, true);
          localStorage.setItem("locationFetched", JSON.stringify(closestShowroomToUser));
          localStorage.setItem("closestTenant", JSON.stringify(closestTenant));
          fetch(`https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=670cbe067a975137800734vmi724b25`)
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              localStorage.setItem("usersLocationData", JSON.stringify({ city: data.address.city, state: data.address.state }));
            })
            .catch((error) => {
              console.error(error);
            });
          setShowroom(closestShowroomToUser.properties);
          setLoading(false);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    try {
      let counter = 0;
      const timesToRetry = 3; // Retry the check 3 times

      const checkLocalStorage = () => {
        if (counter <= timesToRetry) {
          counter += 1;
          let showroomInfo = localStorage.getItem("locationFetched");
          if (showroomInfo) {
            showroomInfo = JSON.parse(showroomInfo);
            setShowroom(showroomInfo.properties);
            setLoading(false);
            clearInterval(intervalId);
          }
        } else {
          clearInterval(intervalId);
        }
      };

      checkLocalStorage();

      const intervalId = setInterval(checkLocalStorage, 2000);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);

  // Check for Chandler or Scottsdale
  const isChandlerOrScottsdale = showroom?.city?.toLowerCase() === 'chandler' || showroom?.city?.toLowerCase() === 'scottsdale';

  return (
    <Container ref={innerRef} type={type}>

      <InfoText type={type}>
        Your Nearest Classy Closets Showroom{isChandlerOrScottsdale ? 's' : ''}:
      </InfoText>

      {loading && <LoadingSpinner width={"25px"} height={"25px"} />}

      {!loading && showroom && (
        <>
          {isChandlerOrScottsdale ? (
            // Custom layout for Chandler and Scottsdale
            <Row>
              <ClearLeft>
                <Link passHref href="/locations/chandler">
                  <InfoTextLink style={linkStyle}>
                    Chandler
                  </InfoTextLink>
                </Link>
                <Link passHref href={`tel:480-530-8960`}>
                  <InfoTextLink style={linkStyle}>
                    480-530-8960
                  </InfoTextLink>
                </Link>
              </ClearLeft>
              <ClearRight>
                <Link passHref href="/locations/scottsdale">
                  <InfoTextLink style={linkStyle}>
                    Scottsdale
                  </InfoTextLink>
                </Link>
                <Link passHref href={`tel:602-888-4075`}>
                  <InfoTextLink style={linkStyle}>
                    602-888-4075
                  </InfoTextLink>
                </Link>
              </ClearRight>
            </Row>
          ) : (
            // Default layout for other locations
            <Row> <ClearLeft>
              <LocationIcon onClick={getCorrectLatLonAndStoreInLocalStorage} />
              <Link passHref href={`/locations/${showroom.city.trim().toLowerCase().replace(/ /g, "-")}`}>
                <InfoTextLink style={linkStyle}>
                  {`${showroom.city}, ${showroom.state}`}
                </InfoTextLink>

              </Link>
              <Link passHref href={`tel:${showroom.phoneFormatted}`}>
                  <InfoTextLink style={linkStyle}>
                   &bull;&nbsp; {` ${showroom.phoneFormatted}`}
                  </InfoTextLink>
                </Link>
            </ClearLeft>
            </Row>
          )}
        </>
      )}

    </Container>
  );
}

InfoBanner.propTypes = {
  type: PropTypes.oneOf([bannerStyle.LIGHT, bannerStyle.DARK]),
};

InfoBanner.defaultProps = {
  type: bannerStyle.LIGHT,
};

export default InfoBanner;
