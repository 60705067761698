import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: ${({ position }) => position ? `${position.top}px` : '100%'};
  left: ${({ position }) => position ? `${position.left}px` : '0'};
  min-width: ${({ isProductsMenu }) => isProductsMenu ? '600px' : '240px'};
  width: ${({ isProductsMenu }) => isProductsMenu ? '600px' : 'auto'};
  background-color: ${({ $isScrolled }) =>
    $isScrolled ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.6)'};
  transition: background-color 0.3s ease;
  transform: translateX(-50%);
  z-index: 1000;
  margin-left: 0;

  @media only screen and (max-width: 768px) {
    min-width: ${({ isProductsMenu }) => isProductsMenu ? '100%' : '240px'};
    width: ${({ isProductsMenu }) => isProductsMenu ? '100%' : 'auto'};
    left: ${({ isProductsMenu }) => isProductsMenu ? '50%' : '0'};
  }
`;

export const ShadowDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  pointer-events: none;
  z-index: -1;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 12px 0;
`;

export const SubMenuSection = styled.div`
  color: ${({ $isScrolled }) => $isScrolled ? '#1F1F1F' : '#FFFFFF'};
  width: 100%;
`;

export const MenuGroupWrap = styled.div`
  display: ${({ isProductsMenu }) => isProductsMenu ? 'grid' : 'flex'};
  ${({ isProductsMenu }) => isProductsMenu ? `
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-template-areas: 
      "a f j"
      "b g k"
      "c h l"
      "d i m"
      "e . .";
    
    & > *:nth-child(1) { grid-area: a; }
    & > *:nth-child(2) { grid-area: b; }
    & > *:nth-child(3) { grid-area: c; }
    & > *:nth-child(4) { grid-area: d; }
    & > *:nth-child(5) { grid-area: e; }
    & > *:nth-child(6) { grid-area: f; }
    & > *:nth-child(7) { grid-area: g; }
    & > *:nth-child(8) { grid-area: h; }
    & > *:nth-child(9) { grid-area: i; }
    & > *:nth-child(10) { grid-area: j; }
    & > *:nth-child(11) { grid-area: k; }
    & > *:nth-child(12) { grid-area: l; }
    & > *:nth-child(13) { grid-area: m; }
  ` : 'flex-direction: column;'}
  gap: 4px;
  padding: 0 20px;
  min-width: ${({ isProductsMenu }) => isProductsMenu ? '400px' : '200px'};
  width: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    ${({ isProductsMenu }) => isProductsMenu ? `
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(7, auto);
      grid-template-areas: 
        "a h"
        "b i"
        "c j"
        "d k"
        "e l"
        "f m"
        "g .";
      
      & > *:nth-child(1) { grid-area: a; }
      & > *:nth-child(2) { grid-area: b; }
      & > *:nth-child(3) { grid-area: c; }
      & > *:nth-child(4) { grid-area: d; }
      & > *:nth-child(5) { grid-area: e; }
      & > *:nth-child(6) { grid-area: f; }
      & > *:nth-child(7) { grid-area: g; }
      & > *:nth-child(8) { grid-area: h; }
      & > *:nth-child(9) { grid-area: i; }
      & > *:nth-child(10) { grid-area: j; }
      & > *:nth-child(11) { grid-area: k; }
      & > *:nth-child(12) { grid-area: l; }
      & > *:nth-child(13) { grid-area: m; }
    ` : 'flex-direction: column;'}
    min-width: auto;
    padding: 0 15px;
  }
`;