import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  z-index: 1000;
  background-color: ${({ theme, $isScrolled }) => ($isScrolled ? theme.white : "transparent")};
  transition: background-color 0.3s ease;
  padding: 20px 0 10px;
  
  @media only screen and (max-width: 768px) {
    padding: 15px 0 5px;
  }
  
  @media only screen and (max-width: 480px) {
    padding: 5px 0;
    height: 70px;
    display: flex;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1200px;
  width: 100%;
`;

export const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`; 