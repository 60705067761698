import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ $isScrolled, theme }) => 
    $isScrolled ? 'transparent' : 'transparent'};
  transition: all 0.3s ease;
`;

export const MenuWrap = styled.div.attrs((props) => ({
  role: "navigation",
}))`
  display: flex;
  padding-top: 7px;
  gap: 1rem;
  background-color: transparent;

  @media only screen and (max-width: 560px) {
    flex-direction: column;
  }
`;
