import React, { useRef, useEffect, useState, useContext, useCallback } from "react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import Header from "../Header";
import HeaderMobile from "../HeaderMobile";
import SimplifiedHeader from "../SimplifiedHeader";
import Footer from "../Footer";
import { IsDesktop, IsMobile } from "../ResponsiveContainers";
import { useModalContext } from "../../context/modalContext";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { Container } from "./styled";
import useMobile from "../../hooks/useMobile";
import useIPad from "../../hooks/useIPad";
import MobileStartButton from "../Menu/MobileStartButton";
const HolidayPopup = dynamic(() => import("../../containers/Modals/ModalHoliday"));
const SchedulePopup = dynamic(() => import("../../containers/SchedulePopup"));
const ModalContactSuccess = dynamic(() => import("../../containers/Modals/ModalContactSuccess"));
import Loading from "../Loading";
import InfoBanner from "../InfoBanner";
import {
  LayoutContainer,
  HeaderContainer,
  MainContainer,
  ContainerWithMax,
  FooterContainer,
  HeaderContainerMobile,
} from "./styled";
import { useHeaderContext } from "../../context/HeaderProvider";
import MainContainerContext from "../../context/MainContainer";
import Head from "next/head";
import { LocationContext } from "../../context/LocationContext";

const AppLayout = ({ children, type, footerBG }) => {
  const location = useContext(LocationContext);
  const { isVisible } = useHeaderContext();
  const wrapperRef = useRef(null);
  const router = useRouter();
  const isHomePage = router.pathname === "/";
  const [cookies, setCookies] = useCookies(["holiday_popup", "schedule_popup"]);
  const isMobile = useMobile();
  const isIPad = useIPad();
  // Use either mobile or iPad for mobile layout
  const useMobileLayout = isMobile || isIPad;
  const [showHolidayPopup, setShowHolidayPopup] = useState(false);
  const [showSchedulePopup, setShowSchedulePopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isHolidayPopupDismissed, setIsHolidayPopupDismissed] = useState(false);
  const pageLoadTime = useRef(null);
  const holidayPopupTimer = useRef(null);
  const schedulePopupTimer = useRef(null);

  // Initialize pageLoadTime on client-side only
  useEffect(() => {
    if (typeof window !== 'undefined') {
      pageLoadTime.current = Date.now();
    }
  }, []);

  // Initialize isScrolled based on whether we're on the homepage or not
  // Homepage should start with transparent header, other pages with white header
  const [isScrolled, setIsScrolled] = useState(!isHomePage);
  const [hideHeader, setHideHeader] = useState(false);

  // Clean up function to clear all timers and reset states
  const cleanupModals = useCallback(() => {
    if (holidayPopupTimer.current) {
      clearTimeout(holidayPopupTimer.current);
      holidayPopupTimer.current = null;
    }
    if (schedulePopupTimer.current) {
      clearTimeout(schedulePopupTimer.current);
      schedulePopupTimer.current = null;
    }
    setShowHolidayPopup(false);
    setShowSchedulePopup(false);
    setShowSuccessModal(false);
    setIsHolidayPopupDismissed(false);
  }, []);

  // Effect to handle holiday popup timing
  useEffect(() => {
    // Skip on server-side rendering
    if (typeof window === 'undefined') {
      return;
    }
    
    const isPopupExpired = () => {
      if (!cookies.holiday_popup) {
        return true;
      }

      try {
        const lastPopupDate = dayjs(cookies.holiday_popup);
        const now = dayjs();
        const hoursSinceLastPopup = now.diff(lastPopupDate, 'hour');
        return hoursSinceLastPopup >= 24;
      } catch (error) {
        return true;
      }
    };

    // Check if the user is in an excluded location
    const isExcludedCity = (city, state) => {
      if (!city || !state) return false;
      
      // Colorado is excluded entirely
      if (state === 'CO') return true;
      
      // Check specific cities in AZ and TX
      const excludedLocations = [
        { city: 'Flagstaff', state: 'AZ' },
        { city: 'Sedona', state: 'AZ' },
        { city: 'Payson', state: 'AZ' },
        { city: 'Prescott', state: 'AZ' }
      ];
      
      return excludedLocations.some(loc => 
        loc.state === state && 
        loc.city.toLowerCase() === city.toLowerCase()
      );
    };
    
    // Check if the user is in an excluded location
    const isExcluded = isExcludedCity(
      location?.userLocation?.city,
      location?.userLocation?.state
    );
    
    console.info('Debug: NEW Holiday popup location check:', { 
      userState: location?.userLocation?.state,
      userCity: location?.userLocation?.city,
      isExcluded,
      locationData: location?.userLocation
    });
    
    const shouldShowPopup = isPopupExpired() && !isExcluded;
    
    console.info('Debug: NEW Holiday popup decision:', { 
      isExcluded,
      isPopupExpired: isPopupExpired(),
      shouldShowPopup
    });
    
    if (shouldShowPopup) {
      holidayPopupTimer.current = setTimeout(() => {
        setShowHolidayPopup(true);
      }, 15000);
    } else if (isExcluded) {
      // If location is excluded, set popup as dismissed
      console.info('Debug: Marking popup as dismissed due to excluded location');
      setIsHolidayPopupDismissed(true);
    }

    return () => {
      if (holidayPopupTimer.current) {
        clearTimeout(holidayPopupTimer.current);
        holidayPopupTimer.current = null;
      }
    };
  }, [cookies.holiday_popup, location?.userLocation]);

  // Separate effect for schedule popup with 90-second delay from page load
  useEffect(() => {
    // Skip on server-side rendering
    if (typeof window === 'undefined') {
      return;
    }
    
    // Check if the schedule popup cookie exists
    const hasScheduleCookie = !!cookies.schedule_popup;
    
    console.info('Debug: Schedule popup check', {
      hasScheduleCookie,
      showHolidayPopup,
      showSchedulePopup,
      showSuccessModal
    });
    
    // Only set up schedule timer if no schedule cookie exists and holiday popup isn't showing
    if (!hasScheduleCookie && !showHolidayPopup && !showSuccessModal) {
      schedulePopupTimer.current = setTimeout(() => {
        setShowSchedulePopup(true);
      }, 90000);
    } else if (hasScheduleCookie) {
      // If cookie exists, make sure popup is closed
      setShowSchedulePopup(false);
    }

    return () => {
      if (schedulePopupTimer.current) {
        clearTimeout(schedulePopupTimer.current);
        schedulePopupTimer.current = null;
      }
    };
  }, [cookies.schedule_popup, showHolidayPopup, showSuccessModal]);

  useEffect(() => {
    // Skip on server-side rendering
    if (typeof window === 'undefined') {
      return;
    }
    
    let lastScrollY = window.scrollY;
    let ticking = false;
    
    // Update mobile detection to include iPads
    let isMobileView = window.innerWidth <= 768;
    
    // Check if device is iOS/iPad
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                 (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    
    // Consider iPads as mobile devices
    if (isIOS && navigator.maxTouchPoints > 1) {
      isMobileView = true;
    }

    const handleResize = () => {
      // Update mobile detection on resize
      isMobileView = window.innerWidth <= 768;
      
      // Always consider iPads as mobile
      if (isIOS && navigator.maxTouchPoints > 1) {
        isMobileView = true;
      }
      
      // Force a scroll check after resize to update header state
      handleScroll();
    };

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      if (!ticking) {
        window.requestAnimationFrame(() => {
          // Set isScrolled based on scroll position AND page type
          // For homepage: transparent at top, white when scrolled
          // For other pages: always white
          if (isHomePage) {
            setIsScrolled(currentScrollY > 0);
          } else {
            // Always keep header white on non-homepage pages
            setIsScrolled(true);
          }
          
          // Determine scroll direction
          const scrollingDown = currentScrollY > lastScrollY;
          const scrollingUp = currentScrollY < lastScrollY;
          
          // For mobile, use a smaller threshold to hide/show header
          // Use even smaller threshold for iOS devices
          const hideThreshold = isIOS ? 20 : (isMobileView ? 30 : 50);
          
          // Only hide header on mobile devices
          // On desktop, always show the header
          if (isMobileView) {
            // Only hide header when scrolling down AND not at the top of the page
            // AND not on the homepage (or scrolled down on homepage)
            if (scrollingDown && currentScrollY > hideThreshold && (currentScrollY > 100 || !isHomePage)) {
              setHideHeader(true);
            } 
            // Show header immediately when scrolling up (with very minimal threshold)
            // For iOS, use a smaller threshold to be more responsive
            else if (scrollingUp && (isIOS || lastScrollY - currentScrollY > 0)) {
              setHideHeader(false);
            }
            
            // Always show header at the top of the page
            if (currentScrollY <= 0) {
              setHideHeader(false);
            }
          } else {
            // On desktop, always show the header
            setHideHeader(false);
          }
          
          lastScrollY = currentScrollY;
          ticking = false;
          
          // More detailed logging for debugging mobile issues
          console.info('Scroll event:', { 
            currentScrollY, 
            scrollingDown, 
            scrollingUp,
            isHomePage,
            isIOS,
            hideHeader: scrollingDown && currentScrollY > hideThreshold && (currentScrollY > 100 || !isHomePage),
            isMobile: isMobileView,
            viewportWidth: window.innerWidth,
            viewportHeight: window.innerHeight
          });
        });
        
        ticking = true;
      }
    };

    // Initial check on mount
    handleScroll();
    
    // Add touch events for mobile devices
    const touchEvents = ['touchmove', 'touchend'];
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize, { passive: true });
    touchEvents.forEach(event => window.addEventListener(event, handleScroll, { passive: true }));
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      touchEvents.forEach(event => window.removeEventListener(event, handleScroll));
    };
  }, [isHomePage]);

  // Debug isScrolled changes
  useEffect(() => {
    if (typeof window !== 'undefined') {
      console.info('📜 AppLayout:', {
        event: 'scroll state changed',
        isScrolled,
        hideHeader,
        isHomePage,
        timestamp: new Date().toISOString()
      });
    }
  }, [isScrolled, hideHeader, isHomePage]);

  const handleHolidayPopupDismiss = useCallback(() => {
    console.info('Debug: Dismissing holiday popup');
    cleanupModals();
    // Set dismissed state after cleanup
    if (typeof window !== 'undefined') {
      requestAnimationFrame(() => {
        setIsHolidayPopupDismissed(true);
      });
    } else {
      setIsHolidayPopupDismissed(true);
    }
  }, [cleanupModals]);

  const handleHolidayFormSuccess = useCallback(() => {
    console.info('Debug: Holiday form success');
    cleanupModals();
    // Set dismissed state after cleanup
    if (typeof window !== 'undefined') {
      requestAnimationFrame(() => {
        setIsHolidayPopupDismissed(true);
      });
    } else {
      setIsHolidayPopupDismissed(true);
    }
  }, [cleanupModals]);

  const handleSuccessModalClose = useCallback(() => {
    console.info('Debug: Success modal close');
    cleanupModals();
  }, [cleanupModals]);

  const handleScheduleFormSuccess = useCallback(() => {
    console.info('Debug: Schedule form success');
    
    // Skip on server-side rendering
    if (typeof window === 'undefined') {
      return;
    }
    
    // Set the cookie to prevent the popup from showing again
    const setCookie = (name, value, options = {}) => {
      document.cookie = `${name}=${value}; path=/; max-age=${86400 * 3}; samesite=strict`;
    };
    setCookie("schedule_popup", new Date().toISOString());
    
    // Close the schedule popup and show the success modal
    setShowSchedulePopup(false);
    
    // Small delay to ensure the schedule popup is fully closed before showing success modal
    setTimeout(() => {
      setShowSuccessModal(true);
    }, 100);
  }, []);

  // Clean up modal states on route change and unmount
  useEffect(() => {
    const handleRouteChange = () => {
      cleanupModals();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      cleanupModals();
    };
  }, [router.events, cleanupModals]);

  const handleMobileButtonClick = useCallback(() => {
    if (typeof window !== 'undefined') {
      console.info('📱 Mobile Button:', {
        event: 'clicked',
        timestamp: new Date().toISOString()
      });
    }
    // Add your mobile button click handler here
  }, []);

  // Check if this layout should show a header or footer
  const showHeader = type ? !type.includes("noHeader") : true;
  const showFooter = type ? !type.includes("noFooter") : true;
  const useSimplifiedHeader = type ? type.includes("simplifiedHeader") : false;

  return (
    <>
      <LayoutContainer className="ant-layout">
        <Head>
          {/* ... */}
        </Head>

        {/* Display Holiday Popup based on state */}
        {typeof window !== 'undefined' && console.info('Debug: NEW Rendering modals with states:', {
          showHolidayPopup,
          showSuccessModal,
          isHolidayPopupDismissed,
          userCity: location?.userLocation?.city,
          userState: location?.userLocation?.state,
          locationData: location?.userLocation
        })}
        <HolidayPopup
          open={showHolidayPopup}
          onRequestClose={handleHolidayPopupDismiss}
        />
        {showSuccessModal && !showSchedulePopup && (
          <ModalContactSuccess
            open={showSuccessModal}
            onRequestClose={handleSuccessModalClose}
          />
        )}
        {showSchedulePopup && !showSuccessModal && (
          <SchedulePopup 
            open={true} 
            onFormSuccess={handleScheduleFormSuccess}
          />
        )}

        {/* InfoBanner must be rendered first for proper stacking context */}
        <InfoBanner role="alert" type="dark" /> 
        
        <MainContainerContext.Provider
          value={{ 
            getContainer: () => {
              // Safe check for server-side rendering
              if (typeof window === 'undefined') {
                return null;
              }
              return wrapperRef.current || window;
            } 
          }}
        >
          {showHeader && (
            <HeaderContainer 
              isvisible={isVisible ? "yes" : "no"} 
              $isScrolled={isScrolled} 
              $hideHeader={hideHeader}
              $useSimplifiedHeader={useSimplifiedHeader}
            >
              {useSimplifiedHeader ? (
                <SimplifiedHeader $isScrolled={isScrolled} />
              ) : useMobileLayout ? (
                <HeaderMobile $isScrolled={isScrolled} />
              ) : (
                <Header $isScrolled={isScrolled} />
              )}
            </HeaderContainer>
          )}
          
          {/* Always render mobile header for small devices and iPads (except for simplified header) */}
          {showHeader && !useSimplifiedHeader && (
            <IsMobile>
              <HeaderContainerMobile $isScrolled={isScrolled} $hideHeader={hideHeader}>
                <HeaderMobile $isScrolled={isScrolled} />
              </HeaderContainerMobile>
            </IsMobile>
          )}
          
          <MainContainer 
            ref={wrapperRef} 
            $isHomePage={isHomePage}
            $useSimplifiedHeader={useSimplifiedHeader}
          >
            {children}
          </MainContainer>
          
          {showFooter && (
            <FooterContainer>
              <Footer footerBG={footerBG} />
            </FooterContainer>
          )}
          
          {/* Show mobile start button for both mobile devices and iPads (except for simplified header) */}
          {useMobileLayout && showHeader && !useSimplifiedHeader && (
            <MobileStartButton 
              $isScrolled={isScrolled} 
              $hideHeader={hideHeader}
              onClick={handleMobileButtonClick}
            />
          )}
        </MainContainerContext.Provider>
      </LayoutContainer>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOfType([
    PropTypes.oneOf(["noHeader", "noFooter", "simplifiedHeader", "noHeader noFooter", "noFooter simplifiedHeader"]),
    PropTypes.string
  ]),
  footerBG: PropTypes.string,
};

export default AppLayout;
